import { Injectable } from '@angular/core';
import { User } from './user';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Product, ProductOption } from '../class/product';
import { Activity } from '../class/activity';
import { environment } from '../../environments/environment';
import { Cart } from '../class/cart';
import { skipWhile } from 'rxjs/operators';
import { Message, MessageType } from '../class/message';

@Injectable({ providedIn: 'root' })
export class SpesaAmica {
    public user: User;
    public redirect: string;
    public activity: Activity;
    public products: Product[];
    public hideproducts: Product[];
    public cart: Cart;

    constructor(private http: HttpClient, private route: Router) {
        this.user = new User(this.http, this.route);
        this.redirect = ""
        this.products = [];
        this.hideproducts = [];
        this.cart = new Cart();
        this.activity = new Activity();

    }
    load(f = () => { }) {

        this.user.load(f);
    }
    geturlownerimage(activity: Activity | number): string {
        const id = typeof activity === 'number' ? activity : activity.id;
        return environment.apiUrl + `api/imageowneractivity/${id}`;
    }
    geturlproductimage(id: number): string {
        return environment.apiUrl + `api/imageproduct/${id}`;

    }
    getdefaultproductimage(): string {
        return environment.apiUrl + "api/image/product.jpg";

    }
    geturlprofile(id: number): string {
        return environment.apiUrl + `api/user/getimageprofile/${id}`;
    }
    getstrProductOption(id: number): string {
        switch (id) {
            case ProductOption.Kg:
                return "Kg";
            case ProductOption.Numero:
                return "Numero";
            case ProductOption.cassetta:
                return "cassetta";
            case ProductOption.cestino:
                return "cestino";
            case ProductOption.hg:
                return "hg";
            default:
                return "caso non previsto";
        }
    }
    geturlactivityimage(activity: Activity | number): string {
        const id = typeof activity === 'number' ? activity : activity.id;
        return environment.apiUrl + `api/imageactivity/${id}`;

    }
    createmessage(m: any): any {
        var m1: Message = m;
        // 2 system,0 mio, 1 altro
        m1.mytype = (m1.type != MessageType.USER) ? 2 : (m1.iduser == this.user.id) ? 0 : 1;
        m1.urlimage = (m1.type != MessageType.USER) ? this.geturlownerimage(this.cart.idactivity) : this.geturlprofile(m1.iduser);
        return m1;
    }

    initmenu() {
        /////////////
        var toggle = document.querySelectorAll(".toggle")[0];
        var nav = document.querySelectorAll("nav")[0];
        var toggle_open_text = "Menu";
        var toggle_close_text = "Close";

        toggle.addEventListener(
            "click",
            function () {
                nav.classList.toggle("open");

            },
            false
        );

        setTimeout(function () {
            //nav.classList.toggle("");
        }, 800);
        //////////////
    }


}