import { Product } from '../class/product';
import { Message } from '../class/message';

export enum CartState {
    OPEN = 0,
    SEND = 1,
    SHOW = 2,
    READY = 3,
    CLOSE = 4,
}
export class Cart {
    public id: number;
    public iduser: number;
    public idactivity: number;
    public idstate: number;
    public numproduct: number;
    public time: string;
    public note: string;
    public products: Product[];
    public messages: Message[];
    constructor() {
        this.products = [];
        this.messages = [];
        this.note = '';
    }
}
