import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from '../services/activity.service';
import { UserService } from '../services/user.service';
import { CartService } from '../services/cart.service';
import { User, UserType } from '../class/user';
import { Activity } from '../class/activity';
import { Product, ProductOption } from '../class/product';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';
import { ImageCropper } from '../class/imagecropper';
import { ProductService } from '../services/product.service';
import { CartState } from '../class/cart';
import { Message, MessageType } from '../class/message';
import { NgForm } from '@angular/forms';

declare var $: any;
export interface FormObject {
  /*
  email: string;
  singleSelect: string;
  multiSelect: string;
  textarea: string;
  radio: string;
  checkbox: any;
  textareaField: string;
  */
  euro: number;
}

declare var $: any;
@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css']
})
export class CartComponent implements OnInit {
  ProductOption = ProductOption; // serve al component.html
  CartState = CartState; // serve al component.html
  public id = "";
  public indexproductselect: number;
  public producttemp: Product;
  public arrProductOption: number[];
  public imagecropper: ImageCropper;
  public strqty: string;
  public idmsg: number;
  public strmessage: string;
  public euro: number;


  model: FormObject;

  @ViewChild('MyForm', { static: false }) MyForm: NgForm;

  constructor(private http: HttpClient, private activateroute: ActivatedRoute,
    private route: Router, private productservice: ProductService, private cartservice: CartService,
    private activityservice: ActivityService, public spesaamica: SpesaAmica, private userservice: UserService) {
    this.producttemp = new Product(0);
    this.arrProductOption = [];
    this.imagecropper = new ImageCropper();
    this.indexproductselect = -1;
    this.strqty = "";
    this.idmsg = 0;
    this.strmessage = "";
    this.euro = 0;


    this.model = ({} as FormObject);

  }

  scrollbottom(): void {
    debugger;
    var objDiv = document.getElementById("msghistoryid");
    objDiv.scrollTop = objDiv.scrollHeight;
    //objDiv.scrollTop = objDiv.scrollHeight;
    //console.log(objDiv.scrollHeight);
    //$("msghistoryid").scrollTop(1100);
    //var a = "msg15";
    //var elmnt = document.getElementById(a);
    //elmnt.scrollIntoView();

  }

  ngOnInit(): void {
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.spesaamica.redirect = "/" + this.id;

    this.cartservice.getCart(parseInt(this.id))
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          this.route.navigate(['/error/']);
          return;
        } else {
          this.spesaamica.cart = result.data;
          this.spesaamica.cart.messages = [];
          this.spesaamica.products = result.data.products;
          this.spesaamica.products.map(p => {
            p.image = this.spesaamica.geturlproductimage(p.id);
            p.strorderoption = this.spesaamica.getstrProductOption(p.idorderoption);
          });
          this.activityservice.getActivity(result.data.idactivity)
            .subscribe(result => {
              if (result.statuscode) {
                alert(result.errorMessage);
                return;
              } else {
                this.spesaamica.activity.id = result.data.id;
                this.spesaamica.activity.namemanager = result.data.namemanager;
                this.spesaamica.activity.name = result.data.name;
                this.spesaamica.activity.address = result.data.address;
                this.spesaamica.activity.city = result.data.city;
                this.spesaamica.activity.description = result.data.description;
                this.spesaamica.activity.timetable = result.data.descriptiontimetable;
                this.spesaamica.activity.activityimage = this.spesaamica.geturlactivityimage(this.spesaamica.activity.id);
                this.spesaamica.activity.ownerimage = this.spesaamica.geturlownerimage(this.spesaamica.activity.id);
                this.spesaamica.activity.prefered = result.data.prefered;
              }
            });
        }
      });
  }
  prepareorder(i): void {
    if (this.spesaamica.user.type == UserType.none) {
      this.route.navigate(['/logon/' + this.spesaamica.activity.id]);
      return;
    }
    alert("più sotto c'è la finestra 'Ordinazione'");
    this.indexproductselect = i;

    this.producttemp = JSON.parse(JSON.stringify(this.spesaamica.products[i]));
    if (this.producttemp.idorderoption == 0) {
      // selezione prima opzione utile
      if (this.producttemp.idoption & ProductOption.Numero) {
        this.producttemp.idorderoption = ProductOption.Numero;
      }
      if (this.producttemp.idoption & ProductOption.cassetta) {
        this.producttemp.idorderoption = ProductOption.cassetta;
      }
      if (this.producttemp.idoption & ProductOption.cestino) {
        this.producttemp.idorderoption = ProductOption.cestino;
      }
      if (this.producttemp.idoption & ProductOption.hg) {
        this.producttemp.idorderoption = ProductOption.hg;
      }
      if (this.producttemp.idoption & ProductOption.Kg) {
        this.producttemp.idorderoption = ProductOption.Kg;
      }
      this.producttemp.strorderoption = this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
      this.strqty = '';
    } else {
      this.strqty = this.producttemp.qty.toString();
    }


    this.arrProductOption[ProductOption.Kg] = this.producttemp.idoption & ProductOption.Kg;
    this.arrProductOption[ProductOption.Numero] = this.producttemp.idoption & ProductOption.Numero;
    this.arrProductOption[ProductOption.cassetta] = this.producttemp.idoption & ProductOption.cassetta;
    this.arrProductOption[ProductOption.cestino] = this.producttemp.idoption & ProductOption.cestino;
    this.arrProductOption[ProductOption.hg] = this.producttemp.idoption & ProductOption.hg;
    $('#makeorder').modal('show');

  }
  changeorderoption(opt: number): void {
    this.producttemp.idorderoption = opt;
    this.producttemp.strorderoption = this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
    this.strqty = '';
  }

  deleteorder(index: number): void {
    this.producttemp = this.spesaamica.products[index];
    this.cartservice.delete(this.spesaamica.cart, this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        this.spesaamica.products.splice(index, 1);
      }
    });
  }
  sendorder(): void {
    if (isNaN(parseFloat(this.strqty)) || parseFloat(this.strqty) == 0) {
      alert('Deve essere un numero maggiore di 0');
      return;
    }
    this.producttemp.qty = parseFloat(this.strqty);
    this.cartservice.order(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        this.spesaamica.cart.numproduct++;
        this.spesaamica.products[this.indexproductselect].qty = this.producttemp.qty;
        this.spesaamica.products[this.indexproductselect].idorderoption = this.producttemp.idorderoption;
        this.spesaamica.products[this.indexproductselect].idproductcart = result.data.idproductcart;
        this.spesaamica.products[this.indexproductselect].strorderoption =
          this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
        $('#makeorder').modal('hide');
      }
    });
  }

  getmessage(): void {

    this.cartservice.message(this.spesaamica.cart.id, this.spesaamica.cart.messages.length)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          result.data.map(m => {
            this.spesaamica.cart.messages.push(this.spesaamica.createmessage(m));
          });
          var t = this;
          setTimeout(
            function () {
              t.scrollbottom();
            }, 10);
        }
      });
  }
  sendmessage(): void {
    var m = new Message();
    m.idcart = this.spesaamica.cart.id;
    m.text = this.strmessage;
    this.cartservice.sendmessage(m)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          result.data.map(m => {
            this.spesaamica.cart.messages.push(this.spesaamica.createmessage(m));
          });
          var t = this;
          setTimeout(
            function () {
              t.scrollbottom();
            }, 10);
        }
      });
    this.strmessage = "";
  }

  send(): void {
    alert('inserire una modale per il caricamento del campo note');
    this.cartservice.send(this.spesaamica.cart)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          this.spesaamica.cart.idstate = CartState.SEND
        }
      });
  }
  ready(): void {
  }
  openForm() {
    this.MyForm.form.reset();
  }
  submitForm() {

    console.log(this.MyForm.form.valid);
    this.MyForm.form.markAllAsTouched();
    if (this.MyForm.form.valid) {
      this.cartservice.ready(this.spesaamica.cart, this.model.euro)
        .subscribe(result => {
          if (result.statuscode) {
            alert(result.errorMessage);
            return;
          } else {
            $('#exampleModal').modal('hide');
            this.spesaamica.cart.idstate = CartState.READY
          }
        });


    }



  }

}
