import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../services/cart.service';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';
import { CartState } from '../class/cart'
import { UserType } from '../class/user';

export class CartXUser {
  public id: number;
  public iduser: number;
  public idstate: CartState;
  public Name: string;
  public price: number;
}

export class CartXActivity {
  public id: number;
  public idactivity: number;
  public idstate: CartState;
  public name: string;
  public price: number;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  CartState = CartState; // serve al component.html
  UserType = UserType; // serve al component.html  

  public cartxactivity: CartXActivity[];
  public cartxuser: CartXUser[];
  constructor(private http: HttpClient, private activateroute: ActivatedRoute,
    private route: Router, private cartservice: CartService, public spesaamica: SpesaAmica) {
    debugger;
    this.cartxactivity = [];
    this.cartxuser = [];
  }


  ngOnInit(): void {
    this.cartservice.list()
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          this.route.navigate(['/error/']);
          return;
        } else {
          debugger;
          if (this.spesaamica.user.type == UserType.customer) {
            this.cartxactivity = result.data;
          } else {
            this.cartxuser = result.data;
          }
        }
      });

  }

}
