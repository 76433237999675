import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
//import { ImageCroppedEvent } from 'ngx-image-cropper';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService, ProfileCustomer } from '../services/user.service';
import { SpesaAmica } from '../class/spesaamica';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces/index';
import { base64ToFile } from '../image-cropper/utils/blob.utils';
import { ImageCropperComponent } from '../image-cropper/component/image-cropper.component';


declare var $: any;

@Component({
  selector: 'app-profilecustomer',
  templateUrl: './profilecustomer.component.html',
  styleUrls: ['./profilecustomer.component.css']
})
export class ProfilecustomerComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imgCropper: ImageCropperComponent;
  customer: ProfileCustomer;
  private userservice: UserService;
  //public imagechanged: boolean;
  profileimage: string;

  //imageChangedEvent: any = '';
  imageBase64String: any = '';
  //croppedImage: any = '';

  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};

  //aspectRatio = 1 / 1;
  roundCropper = true;


  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private route: Router, public spesaamica: SpesaAmica,
    private imageCompress: NgxImageCompressService) {


    //this.imagechanged = false;
    this.profileimage = "";

  }

  resetvalue(): void {
    $('#inputprofile').val(null);
  }
  ngOnInit(): void {
    this.spesaamica.initmenu();

    var t = this;
    this.customer = new ProfileCustomer();
    this.spesaamica.load(function () {

      t.userservice = new UserService(t.http);
      t.profileimage = t.spesaamica.geturlprofile(t.spesaamica.user.id);
      t.userservice.getProfileCustomer()
        .subscribe(result => {
          if (result.statuscode) {
            alert(result.errorMessage);
            return;
          } else {
            t.customer.name = result.data.name;
          }

        })

    });
  };

  lastModifiedDate: string = "";
  lastModified: string = "";
  timepassed: string = "";
  fileChangeEvent(event: any): void {

    if (event.target.files && event.target.files[0]) {
      var file = event.target.files[0];
      $('#editphoto').modal('show');
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //this.localUrl = event.target.result;
        //this.compressFile1(this.localUrl);
        this.compressFilebefore(event.target.result);

      }
      reader.readAsDataURL(file);
      /*
      var minutes = 60;
      var hours = minutes * 60;
      var days = hours * 24;
      var years = days * 365;
      var d = new Date();
      var t = Math.trunc((d.getTime() - file.lastModified) / 1000);
      var anni = Math.round(t / years);
      var giorni = Math.trunc(t / days) - anni * 365;
      var ore = Math.trunc(t / hours) - (anni * 365) - (giorni * 24);
      var minuti = Math.trunc(t / minutes) - (anni * 365 * 24 * 60) - (giorni * 24 * 60) - (ore * 60);
      var secondi = Math.trunc(t) - (anni * 365 * 24 * 60 * 60) - (giorni * 24 * 60 * 60) - (ore * 60 * 60) - (minuti * 60);

      this.lastModified = file.lastModified;
      this.lastModifiedDate = file.lastModifiedDate;
      console.log(Date.parse(file.lastModifiedDate));
      this.timepassed = "anni:" + anni + " giorni:" + giorni + " ore:" + ore + " minuti:" + minuti + " secondi:" + secondi;
      */
    }

    /*
    if (event.target.files.length != 0) {
      console.log("fileEvent: " + event.target.files[0].name);

      this.imageChangedEvent = event;
      console.log("fileChangeEvent");


      //this.imageChangedEvent = event;

      $('#editphoto').modal('show');
    }     
    */




  }


  ////////////////////////////////////////////

  //file: any;
  //localUrl: any;

  //localCompressedURl50100: any;
  //localCompressedURl5050: any;
  //localCompressedURl10050: any;

  //sizeOfOriginalImage: number;

  //sizeOFCompressedImage50100: number;
  //sizeOFCompressedImagel5050: number;
  //sizeOFCompressedImagel10050: number;


  //imgResultBeforeCompress: string;
  //imgResultAfterCompress: string;
  compressFilebefore(image) {
    var orientation = -1;
    var sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        var sizeOFCompressedImagel5050 = this.imageCompress.byteCount(result) / (1024 * 1024)
        if (sizeOFCompressedImagel5050 > 2) {
          this.compressFilebefore(result);
        } else {
          this.imageBase64String = result;
          $('#inputprofile').val(null);
        }
        //this.localCompressedURl5050 = result;

      });
  }


  ////////////////////////////////////////////


  imageCropped(event: ImageCroppedEvent) {
    this.profileimage = event.base64;
    console.log("imageCropped");
  }

  imageLoaded() {
    // show cropper
    console.log("imageLoaded");
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  /*
  okcropper() {
    this.imgCropper.crop()
    //this.profileimage = this.croppedImage;
    this.imagechanged = true;

  }
  */

  cancelcropper() {
    //this.imagechanged = this.imagechanged || false;

  }
  loadImageFailed() {
    // show message
    console.log("loadImageFailed");
  }
  dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  compressFile(objorig, f) {
    if (!objorig.includes('data:image')) {
      f("")
    }
    var orientation = -1;
    var sizeOFCompressedImage = this.imageCompress.byteCount(objorig) / (1024 * 1024);
    if (sizeOFCompressedImage <= 2) {
      f(this.dataURItoBlob(objorig));
      return;
    }
    this.imageCompress.compressFile(objorig, orientation, 50, 100).then(
      result => {
        var sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
        if (sizeOFCompressedImage > 2) {
          this.compressFile(result, f);
          return
        } else {
          f(this.dataURItoBlob(result));
          return;
        }
      });
  }

  saveprofile() {
    var formData = new FormData();

    this.compressFile(this.profileimage, result => {
      if (result == "") {
        formData.append('flagimage', 'false');
      } else {
        formData.append('flagimage', 'true');
        formData.append('croppedImage', result)
      }
      formData.append('customer', JSON.stringify(this.customer));
      this.userservice.setProfileCustomer(formData)
        .subscribe(result => {
          debugger;
          if (result.statuscode) {
            alert(result.errorMessage);
            return;
          } else {
          }

        })
    });


    /*
        if (this.croppedImage != "") {
          formData.append('flagimage', 'true');
          formData.append('croppedImage', this.dataURItoBlob(this.croppedImage))
        } else {
          formData.append('flagimage', 'false');
        }
        formData.append('customer', JSON.stringify(this.customer));
    
    */


    return;
  }

}
