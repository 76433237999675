import { NgModule } from '@angular/core';
import { RouterModule, Routes, Router } from '@angular/router';
import { SpesaamicaComponent } from './spesaamica/spesaamica.component';
import { UseractivitiesComponent } from './useractivities/useractivities.component';
import { LogonComponent } from './logon/logon.component';
import { ProductactivityComponent } from './productactivity/productactivity.component';
import { AdministratorComponent } from './administrator/administrator.component';
import { ProfileActivityComponent } from './profileactivity/profileactivity.component';
import { ProfilecustomerComponent } from './profilecustomer/profilecustomer.component';
import { SpesaamicasplashComponent } from './spesaamicasplash/spesaamicasplash.component';
import { OrdersComponent } from './orders/orders.component';
import { CartComponent } from './cart/cart.component';
import { ProductComponent } from './product/product.component';
import { ErrorComponent } from './error/error.component'
import { HttpClient } from '@angular/common/http';
import { UserService } from './services/user.service';
import { User, UserType } from './class/user';

const routes: Routes = [
  { path: '', component: SpesaamicaComponent },
  { path: 'admin', component: AdministratorComponent },
  { path: 'spesaamica', component: SpesaamicaComponent },
  { path: 'useractivities', component: UseractivitiesComponent },
  { path: 'splash', component: SpesaamicasplashComponent },
  { path: 'profilecustomer', component: ProfilecustomerComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'activity/:id', component: ProductactivityComponent },
  { path: 'cart/:id', component: CartComponent },
  { path: 'profileactivity', component: ProfileActivityComponent },
  //{ path: 'edit/:id', component: ProductComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'logon/:id', component: LogonComponent },
  { path: 'logon', component: LogonComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  private userService: UserService;
  constructor(private http: HttpClient, private router: Router) {
    //this.ngOnInit()
  }

  ngOnInit(): void {
    this.userService = new UserService(this.http);
    this.userService.getTypeUser()
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          this.arrivedUser(result.data)
        }

      })
  }
  arrivedUser(user: User): void {
    switch (user.type) {
      case UserType.none:
        this.router.navigate(['/spesaamica/']);
        break;
      case UserType.activity:
        this.router.navigate(['/activity/']);
        break;
      case UserType.customer:
        this.router.navigate(['/useractivities/']);
        break;
      case UserType.administrator:
        this.router.navigate(['/admin/']);
        break;
      default:
        this.router.navigate(['/spesaamica/']);

    }
  }

}
