
<div class="page-log">
  <ul class="nav nav-pills mb-3 log-p" id="pills-tab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true" (click)="picksignin()">Accedi</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false"(click)="pickregistercustomer()">Registrati</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-negoziante" role="tab" aria-controls="pills-negoziante" aria-selected="false"(click)="pickregisteractivity()">Registrati come Negoziante</a>
  </li>
</ul>
<div class="tab-content" id="pills-tabContent">
  <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
<div class="container cont-log">
    <form>
  <div class="form-group">
    <label for="exampleInputEmail1">Indirizzo Email</label>
    <input type="email" name="mailsignin" [(ngModel)]="p.email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="esempio@dominio.it" >
  </div>  
  <div class="form-group">
    <label for="exampleInputPassword1">Password</label>
    <input type="password" name="passsign" [(ngModel)]="p.password" class="form-control" id="exampleInputPassword1" placeholder="Password">
  </div>
  <div class="form-group form-check">
    <input type="checkbox" class="form-check-input" id="exampleCheck1">
    <label class="form-check-label" for="exampleCheck1">Ricordami</label>
  </div>
  <button type="submit" class="btn btn-primary" (click)="logon()">Accedi</button>
</form>
<div class="row">
  <div class="col-6">
    <a href="">Nome Utente dimenticato?</a>
  </div>
  <div class="col-6">
    <a href="">Password dimenticata?</a>
  </div>
</div>
<p class="sep-op">
  <span>oppure</span>
</p>
<div class="row">
  <div class="col-4">
    <button type="submit" class="btn btn-primary">Apple</button>
  </div>
  <div class="col-4">
    <button type="submit" class="btn btn-primary">Facebook</button>
  </div>
  <div class="col-4">
    <button type="submit" class="btn btn-primary">Google</button>
  </div>
</div>
  </div>
</div>
  <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
    <div class="container cont-log">
      <h2>Crea Account Utente</h2>
      <p>Abbiamo bisogno di alcune informazioni per il tuo Account ....</p>
      <div class="row">
        <div class="col-4">
          <button type="submit" class="btn btn-primary">Apple</button>
        </div>
        <div class="col-4">
          <button type="submit" class="btn btn-primary">Facebook</button>
        </div>
        <div class="col-4">
          <button type="submit" class="btn btn-primary">Google</button>
        </div>
      </div>
      <p class="sep-op">
        <span>oppure usa la tua mail</span>
      </p>
        <form>
      <div class="form-group">
        <label for="exampleInputEmail1">Indirizzo Email</label>
        <input type="email" name="mailregcust" [(ngModel)]="p.email"  class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="esempio@dominio.it">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Password</label>
        <input type="password" name="passregcust" [(ngModel)]="p.password" class="form-control" id="exampleInputPassword1" placeholder="*******">
      </div>
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1">
        <label class="form-check-label cond-blk" for="exampleCheck1">Accetta i Termini e le condizioni</label>
      </div>
      <p class="tercond-p">Quando si accettano i Termini e le condizioni, si acconsente anche all’utilizzo delle proprie informazioni personali per l’elaborazione e la gestione del xxx Account. Per sapere come controlliamo i tuoi dati personali, leggi la nostra informativa sulla privacy.</p>
      <button type="submit" class="btn btn-primary" (click)="logon()">Accedi</button>
    </form>
    <div class="row">
      <div class="col-6">
        <a href="">Nome Utente dimenticato?</a>
      </div>
      <div class="col-6">
        <a href="">Password dimenticata?</a>
      </div>
    </div>
      </div>
  </div>
  <div class="tab-pane fade" id="pills-negoziante" role="tabpanel" aria-labelledby="pills-negozante-tab">
    <div class="container cont-log">
      <h2>Crea Account Utente</h2>
      <p>Abbiamo bisogno di alcune informazioni per il tuo Account ....</p>
        <form>
      <div class="form-group">
        <label for="exampleInputEmail1">Indirizzo Email</label>
        <input type="email"  name="mailregact" [(ngModel)]="p.email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="esempio@dominio.it">
      </div>
      <div class="form-group">
        <label for="exampleInputPassword1">Password</label>
        <input type="password" name="passregact" [(ngModel)]="p.password" class="form-control" id="exampleInputPassword1" placeholder="*******">
      </div>
      <!--
          <div class="form-group">
            <label for="exampledatanascita">Inserisci i dati della tua attività</label>
            <div class="row">
            <div class="col-12">
              <label for="examplegiorno">Nome attività</label>
            <input type="text" name="nameactivity" [(ngModel)]="p.nameactivity"  class="form-control" id="giornonascita" placeholder="Nome attività">
          </div>
          <div class="col-12">
            <label for="examplemese">Indirizzo della tua attività</label>
          <input type="text" name="address" [(ngModel)]="p.address" class="form-control" id="mesenascita" placeholder="Indirizzo">
        </div>
        <div class="col-12">
          <label for="exampleanno">Numero di telefono</label>
        <input type="text"  name="phone" [(ngModel)]="p.phone" class="form-control" id="annonascita"  placeholder="Numero di telefono">
        </div>
          </div>
      <label for="exampleFormControlFile1">Carica una foto della tua attività</label>
      <input type="file" class="form-control-file" id="exampleFormControlFile1">
          </div>        
      -->

      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="exampleCheck1">
        <label class="form-check-label cond-blk" for="exampleCheck1">Accetta i Termini e le condizioni</label>
      </div>
      <p class="tercond-p">Quando si accettano i Termini e le condizioni, si acconsente anche all’utilizzo delle proprie informazioni personali per l’elaborazione e la gestione del xxx Account. Per sapere come controlliamo i tuoi dati personali, leggi la nostra informativa sulla privacy.</p>
      <button type="submit" class="btn btn-primary" (click)="logon()">Accedi</button>
    </form>
    <div class="row">
      <div class="col-6">
        <a href="">Nome Utente dimenticato?</a>
      </div>
      <div class="col-6">
        <a href="">Password dimenticata?</a>
      </div>
    </div>
      </div>
  </div>
</div>
</div>
