<app-navigation ></app-navigation>
<br><br><hr>
<div *ngIf="spesaamica.user.type == UserType.customer" class="container">
  I miei Ordini (OPEN = 0; SEND = 1; SHOW = 2; READY = 3; CLOSE = 4;)
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nome Attività</th>
        <th scope="col">Stato</th>
        <th scope="col">prezzo</th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let chart  of cartxactivity">
        <th scope="row"><a href="#" routerLink="/cart/{{chart.id}}">{{chart.id}}</a></th>
        <td>{{chart.name}}</td>
        <td>{{chart.idstate}}</td>
        <td>{{chart.price}}</td>
      </tr>
    </tbody>
  </table>

</div>
<div *ngIf="spesaamica.user.type == UserType.activity" class="container">
  I miei Ordini (OPEN = 0; SEND = 1; SHOW = 2; READY = 3; CLOSE = 4;)
  <table class="table">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Nome Cliente</th>
        <th scope="col">Stato</th>
        <th scope="col">prezzo</th>
      </tr>
    </thead>
    <tbody>
      <tr  *ngFor="let chart  of cartxuser">
        <th scope="row"><a href="#" routerLink="/cart/{{chart.id}}">{{chart.id}}</a></th>
        <td>{{chart.name}}</td>
        <td>{{chart.idstate}}</td>
        <td>{{chart.price}}</td>
      </tr>
    </tbody>
  </table>

</div>
