import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActivityService } from '../services/activity.service';
import { CartService } from '../services/cart.service';
import { UserService } from '../services/user.service';
import { User, UserType } from '../class/user';
import { Activity } from '../class/activity';
import { Product, ProductOption, ProductStatus } from '../class/product';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';
//import { ImageCropper } from '../class/imagecropper';
import { ProductService } from '../services/product.service';
import { ProductFilterPipe } from '../pipes/productfilterpipe';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces/index';
import { base64ToFile } from '../image-cropper/utils/blob.utils';
import { ImageCropperComponent } from '../image-cropper/component/image-cropper.component';

export enum NewProductStatus {
  IMAGE = 0,
  EDIT = 1,
  CLOSE = 2
}

declare var $: any;
@Component({
  selector: 'app-productactivity',
  templateUrl: './productactivity.component.html',
  styleUrls: ['./productactivity.component.css']
})

export class ProductactivityComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imgCropper: ImageCropperComponent;
  ProductOption = ProductOption; // serve al component.html
  ProductStatus = ProductStatus; // serve al component.html
  NewProductStatus = NewProductStatus; // serve al component.html
  public user: User;
  public id = "";

  public indexproductselect: number;
  public producttemp: Product;
  public newproductstatus: number;
  public arrProductOption: number[];
  //public imagecropper: ImageCropper;
  public myactivity: boolean;
  public strqty: string;




  //public sizeOfOriginalImage: number;
  //public sizeOFCompressedImage: number;

  //imageChangedEvent: any = '';
  imageBase64String: any = '';
  //croppedImage: any = '';

  constructor(private http: HttpClient, private activateroute: ActivatedRoute,
    private route: Router, private productservice: ProductService, private cartservice: CartService,
    private activityservice: ActivityService, public spesaamica: SpesaAmica, private userservice: UserService,
    private imageCompress: NgxImageCompressService) {
    this.newproductstatus = NewProductStatus.CLOSE;
    this.producttemp = new Product(0);

    this.arrProductOption = [];
    //this.imagecropper = new ImageCropper();
    this.indexproductselect = -1;
    this.myactivity = false;
    this.strqty = "";
  }
  addactivity(): void {
    if (this.spesaamica.user.type == UserType.none) {
      this.route.navigate(['/logon/' + this.spesaamica.activity.id]);
      return;
    }
    this.userservice.addactivity(this.spesaamica.activity.id)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          this.spesaamica.activity.prefered = true;
        }
      });
  }

  removeactivity(): void {
    this.userservice.removeactivity(this.spesaamica.activity.id)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          this.spesaamica.activity.prefered = false;
        }
      });
  }


  prepareorder(i): void {
    if (this.spesaamica.user.type == UserType.none) {
      this.route.navigate(['/logon/' + this.spesaamica.activity.id]);
      return;
    }

    this.indexproductselect = i;

    this.producttemp = JSON.parse(JSON.stringify(this.spesaamica.products[i]));
    if (this.producttemp.idorderoption == 0) {
      // selezione prima opzione utile
      if (this.producttemp.idoption & ProductOption.Numero) {
        this.producttemp.idorderoption = ProductOption.Numero;
      }
      if (this.producttemp.idoption & ProductOption.cassetta) {
        this.producttemp.idorderoption = ProductOption.cassetta;
      }
      if (this.producttemp.idoption & ProductOption.cestino) {
        this.producttemp.idorderoption = ProductOption.cestino;
      }
      if (this.producttemp.idoption & ProductOption.hg) {
        this.producttemp.idorderoption = ProductOption.hg;
      }
      if (this.producttemp.idoption & ProductOption.Kg) {
        this.producttemp.idorderoption = ProductOption.Kg;
      }
      this.producttemp.strorderoption = this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
      this.strqty = '';
    } else {
      this.strqty = this.producttemp.qty.toString();
    }


    this.arrProductOption[ProductOption.Kg] = this.producttemp.idoption & ProductOption.Kg;
    this.arrProductOption[ProductOption.Numero] = this.producttemp.idoption & ProductOption.Numero;
    this.arrProductOption[ProductOption.cassetta] = this.producttemp.idoption & ProductOption.cassetta;
    this.arrProductOption[ProductOption.cestino] = this.producttemp.idoption & ProductOption.cestino;
    this.arrProductOption[ProductOption.hg] = this.producttemp.idoption & ProductOption.hg;

    $('#makeorder').modal('show');
  }
  changeorderoption(opt: number): void {
    this.producttemp.idorderoption = opt;
    this.producttemp.strorderoption = this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
    this.strqty = '';
  }
  /*
  calc(symb: string): void {
    if (symb == '.' && this.strqty.includes('.')) return;
    this.strqty = (symb == '') ? '' : this.strqty.concat(symb);
  }
  */
  deleteorder(index: number, productstatus: ProductStatus): void {
    this.producttemp = (productstatus == ProductStatus.HIDE) ?
      this.spesaamica.hideproducts[index] :
      this.spesaamica.products[index];
    this.cartservice.delete(this.spesaamica.cart, this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        if (productstatus == ProductStatus.HIDE) {
          this.spesaamica.hideproducts.splice(index, 1);
        } else {
          this.spesaamica.products[index].idproductcart = 0;
          this.spesaamica.products[index].idorderoption = ProductOption.Kg;
          this.spesaamica.products[index].strorderoption = "Kg";
          this.spesaamica.products[index].qty = 0;
        }

      }
    });
  }

  hideproduct(index: number): void {
    this.producttemp = this.spesaamica.products[index];
    this.productservice.hide(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        this.spesaamica.products.splice(this.indexproductselect, 1);
        this.producttemp.idstate = ProductStatus.HIDE;
        this.spesaamica.hideproducts.push(this.producttemp);
        this.sort();
      }
    });
  }

  showproduct(index: number): void {
    return;
    this.producttemp = this.spesaamica.products[index];
    this.productservice.show(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        this.spesaamica.hideproducts.splice(this.indexproductselect, 1);
        this.producttemp.idstate = ProductStatus.READY;
        this.spesaamica.products.push(this.producttemp);
        this.sort();
      }
    });
  }

  deleteproduct(index: number, productstatus: ProductStatus): void {
    this.producttemp = (productstatus == ProductStatus.HIDE) ?
      JSON.parse(JSON.stringify(this.spesaamica.hideproducts[index])) :
      JSON.parse(JSON.stringify(this.spesaamica.products[index]));
    this.productservice.delete(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        if (this.producttemp.idstate == ProductStatus.READY) {
          this.spesaamica.products.splice(index, 1);
        } else {
          this.spesaamica.hideproducts.splice(index, 1);
        }

      }
    });
  }

  sendorder(): void {
    if (isNaN(parseFloat(this.strqty)) || parseFloat(this.strqty) == 0) {
      alert('Deve essere un numero maggiore di 0');
      return;
    }
    this.producttemp.qty = parseFloat(this.strqty);
    this.cartservice.order(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        this.spesaamica.cart.numproduct++;
        this.spesaamica.products[this.indexproductselect].qty = this.producttemp.qty;
        this.spesaamica.products[this.indexproductselect].idorderoption = this.producttemp.idorderoption;
        this.spesaamica.products[this.indexproductselect].idproductcart = result.data.idproductcart;
        this.spesaamica.products[this.indexproductselect].strorderoption =
          this.spesaamica.getstrProductOption(this.producttemp.idorderoption);
        $('#makeorder').modal('hide');
      }
    });
  }

  editproduct(i, productstatus: ProductStatus): void {
    this.indexproductselect = i;
    this.producttemp = (productstatus == ProductStatus.HIDE) ?
      JSON.parse(JSON.stringify(this.spesaamica.hideproducts[i])) :
      JSON.parse(JSON.stringify(this.spesaamica.products[i]));
    this.arrProductOption[ProductOption.Kg] = this.producttemp.idoption & ProductOption.Kg;
    this.arrProductOption[ProductOption.Numero] = this.producttemp.idoption & ProductOption.Numero;
    this.arrProductOption[ProductOption.cassetta] = this.producttemp.idoption & ProductOption.cassetta;
    this.arrProductOption[ProductOption.cestino] = this.producttemp.idoption & ProductOption.cestino;
    this.arrProductOption[ProductOption.hg] = this.producttemp.idoption & ProductOption.hg;
    $('#editphoto').modal('show');
  }
  submitForm() {

  }


  resetvalue(): void {
    $('input').val(null);
  }

  lastModifiedDate: string = "";
  lastModified: string = "";
  timepassed: string = "";
  fileEvent(event: any): void {

    if (event.target.files.length != 0) {
      console.log("fileEvent: " + event.target.files[0].name);
      var file = event.target.files[0];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //this.localUrl = event.target.result;
        //this.compressFile1(this.localUrl);
        this.compressFilebefore(event.target.result);

      }
      reader.readAsDataURL(file);
      var minutes = 60;
      var hours = minutes * 60;
      var days = hours * 24;
      var years = days * 365;
      var d = new Date();
      var t = Math.trunc((d.getTime() - file.lastModified) / 1000);
      var anni = Math.round(t / years);
      var giorni = Math.trunc(t / days) - anni * 365;
      var ore = Math.trunc(t / hours) - (anni * 365) - (giorni * 24);
      var minuti = Math.trunc(t / minutes) - (anni * 365 * 24 * 60) - (giorni * 24 * 60) - (ore * 60);
      var secondi = Math.trunc(t) - (anni * 365 * 24 * 60 * 60) - (giorni * 24 * 60 * 60) - (ore * 60 * 60) - (minuti * 60);

      this.lastModified = file.lastModified;
      this.lastModifiedDate = file.lastModifiedDate;
      console.log(Date.parse(file.lastModifiedDate));
      this.timepassed = "anni:" + anni + " giorni:" + giorni + " ore:" + ore + " minuti:" + minuti + " secondi:" + secondi;



      //      this.imagecropper.fileChangeEvent(event);
      //      this.imagecropper.showcropper();

      this.arrProductOption[ProductOption.Kg] = this.producttemp.idoption & ProductOption.Kg;
      this.arrProductOption[ProductOption.Numero] = this.producttemp.idoption & ProductOption.Numero;
      this.arrProductOption[ProductOption.cassetta] = this.producttemp.idoption & ProductOption.cassetta;
      this.arrProductOption[ProductOption.cestino] = this.producttemp.idoption & ProductOption.cestino;
      this.arrProductOption[ProductOption.hg] = this.producttemp.idoption & ProductOption.hg;
      this.newproductstatus = NewProductStatus.IMAGE;
      $('#editphoto').modal('show');

    }

  }
  compressFilebefore(image) {
    var orientation = -1;
    var sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        var sizeOFCompressedImagel5050 = this.imageCompress.byteCount(result) / (1024 * 1024)
        if (sizeOFCompressedImagel5050 > 2) {
          this.compressFilebefore(result);
        } else {
          this.imageBase64String = result;
          $('#inputprofile').val(null);
        }
      });
  }


  addremoveoption($opt): void {
    if (this.producttemp.idoption & $opt) {
      this.producttemp.idoption = this.producttemp.idoption ^ $opt;
      this.arrProductOption[$opt] = 0;
    } else {
      this.producttemp.idoption = this.producttemp.idoption | $opt;
      this.arrProductOption[$opt] = 1;
    }
  }


  SaveProduct() {
    this.producttemp.name = this.producttemp.name.toUpperCase();
    this.productservice.set(this.producttemp).subscribe(result => {
      if (result.statuscode) {
        alert(result.errorMessage);
        return;
      } else {
        var tstatus = this.producttemp.idstate;
        if (tstatus == ProductStatus.HIDE) {
          this.spesaamica.hideproducts.splice(this.indexproductselect, 1);
        } else {
          this.spesaamica.products.splice(this.indexproductselect, 1);
        }
        this.producttemp = result.data;
        this.producttemp.image = this.spesaamica.geturlproductimage(this.producttemp.id);
        var t = this;
        this.producttemp.fileEvent = function (event: any, i, productstatus: ProductStatus) {
          t.indexproductselect = i;
          t.producttemp = JSON.parse(JSON.stringify(t.producttemp));
          t.fileEvent(event);
        }
        this.producttemp.idstate = ProductStatus.READY;

        this.spesaamica.products.push(this.producttemp);
        this.sort();

        this.producttemp.numorders = 0;
        $('#editphoto').modal('hide');
      }

    });
  }

  newproduct(): Product {
    var t = this;
    this.compressionmessage = "";
    this.producttemp = new Product(this.spesaamica.user.idactivity);
    this.producttemp.fileEvent = function (event: any, i) {
      t.indexproductselect = i;
      t.producttemp = JSON.parse(JSON.stringify(this));
      t.fileEvent(event);
    }
    return this.producttemp;
  }
  compressionmessage: string;
  compressFile(objorig, objdest: any) {
    var orientation = -1;
    this.imageCompress.compressFile(objorig, orientation, 50, 100).then(
      result => {
        var sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
        this.compressionmessage = this.compressionmessage + "=>" + sizeOFCompressedImage.toFixed(2) + "MB";
        if (sizeOFCompressedImage > 2) {
          this.compressFile(result, objdest);
          return
        } else {
          objdest.image = result;
        }
      });
  }
  newnext() {
    this.arrProductOption[ProductOption.Kg] = this.producttemp.idoption & ProductOption.Kg;
    this.arrProductOption[ProductOption.Numero] = this.producttemp.idoption & ProductOption.Numero;
    this.arrProductOption[ProductOption.cassetta] = this.producttemp.idoption & ProductOption.cassetta;
    this.arrProductOption[ProductOption.cestino] = this.producttemp.idoption & ProductOption.cestino;
    this.arrProductOption[ProductOption.hg] = this.producttemp.idoption & ProductOption.hg;
    this.compressionmessage = "";
    //var sizeOFCompressedImage = this.imageCompress.byteCount(this.imagecropper.croppedImage) / (1024 * 1024);
    //this.compressionmessage = this.compressionmessage + "=>" + sizeOFCompressedImage.toFixed(2) + "MB";
    //if (sizeOFCompressedImage > 2) {
    //  this.compressFile(this.imagecropper.croppedImage, this.producttemp);
    //} else {
    //  this.producttemp.image = this.imagecropper.croppedImage;
    //}
    this.imgCropper.crop()
    //this.producttemp.image = this.croppedImage;

    this.newproductstatus = NewProductStatus.EDIT;
  }
  imageCropped(event: ImageCroppedEvent) {
    this.producttemp.image = event.base64;
    console.log("imageCropped");
  }

  sort() {

    this.spesaamica.products.sort((a: Product, b: Product) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    });

    this.spesaamica.hideproducts.sort((a: Product, b: Product) => {
      if (a.name.toUpperCase() < b.name.toUpperCase()) {
        return -1;
      } else if (a.name.toUpperCase() > b.name.toUpperCase()) {
        return 1;
      } else {
        return 0;
      }
    });

  }

  ngOnInit(): void {
    this.spesaamica.initmenu();
    this.id = this.activateroute.snapshot.paramMap.get('id');
    this.spesaamica.redirect = "/" + this.id;

    this.activityservice.getActivityfull(parseInt(this.id))
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          var t = this;
          this.spesaamica.activity.id = result.data.id;
          this.spesaamica.activity.namemanager = result.data.namemanager;
          this.spesaamica.activity.name = result.data.name;
          this.spesaamica.activity.address = result.data.address;
          this.spesaamica.activity.city = result.data.city;
          this.spesaamica.activity.description = result.data.description;
          this.spesaamica.activity.timetable = result.data.descriptiontimetable;
          this.spesaamica.activity.activityimage = this.spesaamica.geturlactivityimage(this.spesaamica.activity.id);
          this.spesaamica.activity.ownerimage = this.spesaamica.geturlownerimage(this.spesaamica.activity.id);
          this.spesaamica.activity.prefered = result.data.prefered;
          this.spesaamica.cart = result.data.cart;
          result.data.products.map(p => {
            p.image = this.spesaamica.geturlproductimage(p.id);
            p.strorderoption = this.spesaamica.getstrProductOption(p.idorderoption);
            p.fileEvent = function (event: any, i, productstatus: ProductStatus) {
              t.indexproductselect = i;
              t.producttemp = JSON.parse(JSON.stringify(p));
              t.fileEvent(event);
            }
            if (p.idstate == ProductStatus.READY) {
              this.spesaamica.products.push(p);
            } else {
              this.spesaamica.hideproducts.push(p);
            }

          });
          this.sort();
          this.myactivity = this.spesaamica.user.idactivity == this.spesaamica.activity.id;
        }
      });

  }
}
