<app-navigation ></app-navigation>
<div class="container">

    <div class="row">

<section >
<div >
    <div>
        <!--
        <span class="btn btn-primary btn-file" id="btnprofile">
            Seleziona Immagine personale
            <input id="inputprofile" type="file" accept="image/*" capture (change)="fileEvent($event,0)" (click)="resetvalue()">
        </span>
        <span class="btn btn-primary btn-file" id="btnact">
            Seleziona Immagine Activity
            <input id="inputact" type="file" accept="image/*" capture (change)="fileEvent($event,1)" (click)="resetvalue()">
        </span>
        -->

        <button (click)="saveprofile()">Save Profile</button>
    </div>
    <div class="form-group">
        <label for="idmail">Nome Proprietario</label>
        <input type="text" name="name" [(ngModel)]="activity.namemanager" class="form-control" id="idname" aria-describedby="emailHelp" placeholder="Nome" >
    </div> 
    <div class="form-group">
        <label for="idmail">Nome</label>
        <input type="text" name="name" [(ngModel)]="activity.name" class="form-control" id="idmail" aria-describedby="emailHelp" placeholder="Nome" >
    </div>  
    <div class="form-group">
        <label for="idaddress">Indirizzo</label>
        <input type="text" name="address" [(ngModel)]="activity.address" class="form-control" id="iaddress" aria-describedby="emailHelp" placeholder="Indirizzo" >
    </div>  
    <div class="form-group">
        <label for="idcity">Citta</label>
        <input type="text" name="city" [(ngModel)]="activity.city" class="form-control" id="idcity" aria-describedby="emailHelp" placeholder="Citta" >
    </div> 
    <div class="form-group">
        <label for="iddescription">Descrizione</label>
        <input type="text" name="description" [(ngModel)]="activity.description" class="form-control" id="iddescription" aria-describedby="emailHelp" placeholder="descrizione" >
    </div>    
    <div class="form-group">
        <label for="idtimetable">Orari</label>
        <input type="text" name="timetable" [(ngModel)]="activity.timetable" class="form-control" id="idtimetable" aria-describedby="emailHelp" placeholder="descrizione" >
    </div> 

    <div class="btn-file" style="height: 200px; background-color: rgba(72, 58, 199, 0.1);">
        <input id="inputprofile" type="file" accept="image/*" (change)="fileEvent($event,0)" (click)="resetvalue()">
        <div  class="h-100 d-inline-block" style="width: 120px;"><img class="img-thumbnail cropper-face" [src]="profileimage" /></div>
    </div>
    <div class="btn-file" style="height: 200px; background-color: rgba(58, 199, 65, 0.1);">
        <input id="inputact" type="file" accept="image/*" (change)="fileEvent($event,1)" (click)="resetvalue()">
        <div  class="h-100 d-inline-block" style="width: 120px;"><img class="img-thumbnail" [src]="activityimage" /></div>
    </div>
</div>

<div class="modal fade" id="editphoto" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
            <h5 *ngIf="typeimage==0" class="modal-title" id="exampleModalLabel">Ritaglio Immagine Personale</h5>
            <h5 *ngIf="typeimage==1" class="modal-title" id="exampleModalLabel">Ritaglio Immagine Banco</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div><!--modal-header-->
        <div class="modal-body">
            <image-cropper 
            [imageBase64]="imageBase64String"
            [maintainAspectRatio]="true"
            [aspectRatio]="aspectRatio"
            [roundCropper]="roundCropper"
            [alignImage]="'center'"
            format="png"
            [autoCrop]="false"
            [alignImage]="'center'"
            (imageCropped)="imageCropped($event)"
            >
        </image-cropper> 
        <!--
            (imageCropped)="imagecropper.imageCropped($event)"
            (imageLoaded)="imagecropper.imageLoaded()"
            (loadImageFailed)="imagecropper.loadImageFailed()"
        -->


        </div><!--modal-body-->
             
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="imgCropper.crop()">OK</button>
        </div> <!--modal-footer-->
      </div> <!--modal-content-->
    </div> <!--modal-dialog-->
  </div>  <!--id="exampleModal"-->


</section>
</div>
</div>
