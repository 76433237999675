import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spesaamicasplash',
  templateUrl: './spesaamicasplash.component.html',
  styleUrls: ['./spesaamicasplash.component.css']
})
export class SpesaamicasplashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
