import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from './class/spesaamica';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'spesaamica';
  constructor(public spesaamica: SpesaAmica) {
    this.spesaamica.load();
  }



}
