import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SettingService, Setting } from '../services/setting.service';
import { User, UserType } from '../class/user';
import { OneSignal } from '../class/onesignal';
//import { OneSignalService } from 'ngx-onesignal';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';

@Component({
  selector: 'app-spesaamica',
  templateUrl: './spesaamica.component.html',
  styleUrls: ['./spesaamica.component.css']
})
export class SpesaamicaComponent implements OnInit {
  constructor(public spesaamica: SpesaAmica) {

  }

  ngOnInit() {
    this.spesaamica.initmenu();
  }





  ////////////////////////////////
  deferredPrompt: any;
  showButton = false;
  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showButton = true;
  }
  addToHomeScreen() {
    // hide our user interface that shows our A2HS button
    this.showButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt');
        } else {
          console.log('User dismissed the A2HS prompt');
        }
        this.deferredPrompt = null;
      });
  }


  ///////////////////////////


}
