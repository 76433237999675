//import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces/index';
import { base64ToFile } from '../image-cropper/utils/blob.utils';
export class ImageCropper {

    croppedImage: any = '';
    containWithinAspectRatio = false;

    public imagechanged: boolean;
    imageChangedEvent: any = '';
    constructor() { }

    dataURItoBlob(): Blob {
        var dataURI = this.croppedImage
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        let ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }
    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log("fileChangeEvent" + event);
    }
    imageCropped(event: ImageCroppedEvent) {

        this.croppedImage = event.base64

        console.log("imageCropped");
    }
    imageLoaded() {
        // show cropper
        console.log("imageLoaded");
    }
    cropperReady() {
        // cropper ready
        console.log("cropperReady");

    }
    showcropper() {
        //alert('qui deve comparire il cropper adesso è mostrato sotto');
    }
    okcropper() {
        this.imagechanged = true;
        alert('qui deve sparire il cropper');
    }
    cancelcropper() {
        this.imagechanged = this.imagechanged || false;
        alert('qui deve sparire il cropper');
    }
    loadImageFailed() {
        // show message
        console.log("loadImageFailed");

    }

}