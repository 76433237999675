
export class Activity {
    public id: number;
    public namemanager: string;
    public name: string;
    public address: string;
    public city: string;
    public description: string;
    public timetable: string;
    public activityimage: string;
    public ownerimage: string;
    public prefered: boolean;

    constructor() {

    }

}
