import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingService, Setting } from '../services/setting.service';
import { User, UserType } from '../class/user';
import { OneSignal } from '../class/onesignal';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.css']
})
export class AdministratorComponent implements OnInit {

  private settingService: SettingService;
  public user: User;
  constructor(private http: HttpClient, private route: Router) {
    this.user = new User(this.http, this.route);
  }

  ngOnInit() {

    this.settingService = new SettingService(this.http);
    this.settingService.getSettingUserActivities()
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          this.arrivedSetting(result.data)
        }

      })



  }

  arrivedSetting(setting: Setting): void {
    if (setting.user.type != UserType.administrator) {
      this.route.navigate(['/error/']);
      return;
    }
    this.user = setting.user;
    if (setting.appid != "") new OneSignal(setting.appid, setting.user.id);



  }
}
