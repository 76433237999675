
export enum MessageType {
    USER = 0,
    SEND = 1,
    SHOW = 2,
    READY = 3,
    CLOSE = 4,
    ALERT = 5
}
export class Message {
    public id: number;
    public idcart: number;
    public iduser: number;
    public time: string;
    public type: MessageType
    public text: string;
    public readuser: boolean
    public readactivity: boolean

    public mytype: number // 2 system,0 mio, 1 altro
    public urlimage: string;
    constructor() {
    }

}
