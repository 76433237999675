import { SettingService, Setting } from '../services/setting.service';
import { UserService, Person, ServiceType } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { OneSignal } from '../class/onesignal';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
export enum ProductOption {
    Kg = 2,
    hg = 4,
    cestino = 8,
    cassetta = 16,
    Numero = 32
}
export enum ProductStatus {
    READY = 0,
    HIDE = 1,
    DELETED = 2,
}

export class Product {
    public id: number;
    public idactivity: number;
    public photo: string;
    public time: string;
    public name: string;
    public idstate: number;
    public description: string;
    public idoption: number;
    public imagetime: string;
    public finish: string;
    public offer: string;
    public image: string;

    public numorders: number;
    public idproductcart: number;
    public idorderoption: number;
    public strorderoption: string
    public qty: number;
    public fileEvent(event: any, i: any, productstatus: ProductStatus) { console.error("Product.fileEvent call error") };

    constructor(idactivity: number) {
        this.id = 0;
        this.idactivity = idactivity;
        this.photo = "";
        this.time = ""
        this.name = ""
        this.description = ""
        this.idoption = ProductOption.Kg;
        this.finish = "";
        this.offer = "";
        this.image = environment.apiUrl + "api/image/product.jpg";


        this.numorders = 0;
        this.idproductcart = 0;
        this.idorderoption = ProductOption.Kg;
        this.strorderoption = "Kg";
        this.qty = 0;
    }








}