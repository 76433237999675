<app-navigation ></app-navigation>
<br><br><hr>
<div class="container">
<!-- Nav pills -->
<h2>Il tuo Ordine su <a routerLink="/activity/{{spesaamica.activity.id}}">{{spesaamica.activity.name}}</a></h2>
<h3>{{spesaamica.cart.products.length}} Articoli</h3>
<h2>Stato:{{spesaamica.cart.idstate}}</h2>OPEN = 0; SEND = 1; SHOW = 2; READY = 3; CLOSE = 4;<br>
Time:{{spesaamica.cart.time}}<br>
<ng-container *ngIf="spesaamica.cart.iduser==spesaamica.user.id">
  <button *ngIf="spesaamica.cart.idstate == CartState.OPEN" class="btn btn-primary" (click)="send()">Invia</button>
  <button *ngIf="spesaamica.cart.idstate != CartState.OPEN" class="btn btn-secondary" (click)="ready()">Invia</button>
</ng-container>
<ng-container *ngIf="spesaamica.cart.iduser!=spesaamica.user.id">
  <button *ngIf="spesaamica.cart.idstate == CartState.SHOW" type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" (click)="openForm()">
    Pronto
  </button>
  <button *ngIf="spesaamica.cart.idstate != CartState.SHOW" type="button" class="btn btn-secondary" >
    Pronto
  </button>             
</ng-container>
<br><br>
<ul class="nav nav-tabs">

  <li class="nav-item">
    <a class="nav-link active" data-toggle="pill" href="#compactlist">Compatta</a>
  </li>
  <li *ngIf="spesaamica.cart.iduser==spesaamica.user.id" class="nav-item">
    <a class="nav-link " data-toggle="pill" href="#fulllist">Prodotti</a>
  </li>
  <li class="nav-item">
      <a class="nav-link" data-toggle="pill" href="#message" (click)="getmessage()" >Messaggi</a>
    </li>
  </ul>
  
  <!-- Tab panes -->
  <div class="tab-content">

    <div class="tab-pane container active" id="compactlist">
      <div class="row">

        <table class="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Prodotto</th>
              <th scope="col">Ordine</th>
              <th scope="col">Operazioni</th>
            </tr>
          </thead>
          <tbody>
            <tr  *ngFor="let product  of spesaamica.products; let i = index">
              <th scope="row">{{product.id}}</th>
              <td>{{product.name}}</td>
              <td>{{product.qty}} {{product.strorderoption}}</td>
              <td>
                <span *ngIf="product.idstate!=0">Prodotto non più sul banco</span>
                <ng-container *ngIf="spesaamica.cart.idstate==0">
                  <button class="btn btn-primary" (click)="deleteorder(i)">Elimina</button>
                  <button *ngIf="product.idstate==0" class="btn btn-primary" (click)="prepareorder(i)">Modifica</button>
                </ng-container> 
                <ng-container  *ngIf="spesaamica.cart.idstate!=0">  
                  <button class="btn btn-secondary" >Elimina</button>
                  <button class="btn btn-secondary" >Modifica</button>
                </ng-container>  
              </td>
            </tr>
          </tbody>
        </table>


      </div>
    </div>
    <div *ngIf="spesaamica.cart.iduser==spesaamica.user.id" class="tab-pane container fade " id="fulllist">

        <div class="row">
          <div *ngFor="let product  of spesaamica.products; let i = index" class="col-lg-4 col-md-6 mb-4">
            <div class="card h-100">
              <a href="#"><img class="card-img-top" [src]="product.image" alt=""></a>
              <div class="card-body">
                <h4 class="card-title">
                  <a href="#">{{product.name}}</a>
                </h4>
                <p class="card-text">Descrizione: {{product.description}}</p>
                <p class="card-text">modo ordinazione {{product.idoption}}</p>
                <p class="card-text">infofoto {{product.imagetime}} con codice colore: verde (foto di giornata) giallo (foto non di giornata) Rosso (Immagine caricata)</p>
        
              </div>
              <div class="card-footer">
                <span *ngIf="product.idstate!=0">Prodotto non più sul banco</span><br>
                  <ng-container *ngIf="spesaamica.cart.idstate==0">
                    <button class="btn btn-primary" (click)="deleteorder(i)">Elimina</button>
                    <button *ngIf="product.idstate==0" class="btn btn-primary" (click)="prepareorder(i)">Modifica</button>
                  </ng-container> 
                  <ng-container  *ngIf="spesaamica.cart.idstate!=0">  
                    <button class="btn btn-secondary" >Elimina</button>
                    <button class="btn btn-secondary" >Modifica</button>
                  </ng-container>                    
                  <p class="card-text">Quantità:{{product.qty}}</p>
                  <p class="card-text">Tipo:{{product.strorderoption}}</p>
              </div>
            </div>
          </div>
        </div>
    </div> 
    <div class="tab-pane container fade" id="message">
    <div class="containermessage">
      <h3 class=" text-center">Messaggi</h3>
  <!--    https://bootsnipp.com/snippets/1ea0N -->
      <div class="messaging">
            <div class="inbox_msg">
              <div class="mesgs" >
                <div class="msg_history" id="msghistoryid">
                    <ng-container *ngFor="let message  of this.spesaamica.cart.messages; let i = index">
                      <div *ngIf="message.mytype==2" class="system_msg" [id]="'msg' + message.id">
                        <div class="incoming_msg_img"> <img [src]="message.urlimage" alt="sunil"> </div>
                        <div class="system_msg">
                          <div class="system_withd_msg">
                            <p>Tipo: {{message.type}} | {{message.text}}</p>
                            <span class="time_date"> {{message.time}}</span></div>
                        </div>
                      </div>                    
                      <div *ngIf="message.mytype==1" class="incoming_msg" [id]="'msg' + message.id">
                        <div class="incoming_msg_img"> <img [src]="message.urlimage" alt="sunil"> </div>
                        <div class="received_msg">
                          <div class="received_withd_msg">
                            <p>{{message.text}}</p>
                            <span class="time_date"> {{message.time}}</span></div>
                        </div>
                      </div>
                      <div *ngIf="message.mytype==0" class="outgoing_msg" [id]="'msg' + message.id" >
                        <div class="sent_msg">
                          <p >{{message.text}}</p>
                          <span class="time_date"> {{message.time}}</span> </div>
                      </div>
                    </ng-container>
                    <!--
                  <div class="incoming_msg">
                    <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                    <div class="received_msg">
                      <div class="received_withd_msg">
                        <p>Test, which is a new approach to have</p>
                        <span class="time_date"> 11:01 AM    |    Yesterday</span></div>
                    </div>
                  </div>
                  <div class="outgoing_msg">
                    <div class="sent_msg">
                      <p>Apollo University, Delhi, India Test</p>
                      <span class="time_date"> 11:01 AM    |    Today</span> </div>
                  </div>
                  <div class="incoming_msg">
                    <div class="incoming_msg_img"> <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil"> </div>
                    <div class="received_msg">
                      <div class="received_withd_msg">
                        <p>We work directly with our designers and suppliers,
                          and sell direct to you, which means quality, exclusive
                          products, at a price anyone can afford.</p>
                        <span class="time_date"> 11:01 AM    |    Today</span></div>
                    </div>
                  </div>                    
                  -->
                </div>
                <div class="type_msg">
                  <div class="input_msg_write">
                    <input type="text"  [(ngModel)]="strmessage" class="write_msg" placeholder="Scrivi il messaggio" />
                    <button class="msg_send_btn" type="button" (click)="sendmessage()">></button>
                  </div>
                </div>
              </div>
            </div>

            
      </div>
    </div>







</div>
</div>


<section class="container" >
  <div class="modal fade" id="makeorder" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Ordinazione</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="card h-100">
              <a href="#"><img class="card-img-top" [src]="producttemp.image" alt=""></a>
              <div class="card-body">
                <h4 class="card-title">
                  <a href="#">{{producttemp.name}}</a>
                </h4>
                <p class="card-text">Descrizione: {{producttemp.description}}</p>
                <label for="exampleInputEmail1">Tipo Scelta ({{producttemp.strorderoption}}) </label>
                <div class="form-group">
                  <button *ngIf="arrProductOption[ProductOption.Kg]" type="button" 
                    [ngClass]="{'btn btn-primary': producttemp.idorderoption!=ProductOption.Kg, 'btn btn-success': producttemp.idorderoption==ProductOption.Kg}"
                    data-toggle="button" aria-pressed="false" autocomplete="off" (click)="changeorderoption(ProductOption.Kg)">
                    Kg
                  </button> 
                  <button *ngIf="arrProductOption[ProductOption.hg]" type="button" 
                    [ngClass]="{'btn btn-primary': producttemp.idorderoption!=ProductOption.hg, 'btn btn-success': producttemp.idorderoption==ProductOption.hg}"
                    data-toggle="button" aria-pressed="false" autocomplete="off" (click)="changeorderoption(ProductOption.hg)">
                    hg
                  </button> 
                  <button *ngIf="arrProductOption[ProductOption.cestino]" type="button" 
                    [ngClass]="{'btn btn-primary': producttemp.idorderoption!=ProductOption.cestino, 'btn btn-success': producttemp.idorderoption==ProductOption.cestino}"
                    data-toggle="button" aria-pressed="false" autocomplete="off" (click)="changeorderoption(ProductOption.cestino)">
                    cestino
                  </button> 
                  <button *ngIf="arrProductOption[ProductOption.cassetta]" type="button" 
                    [ngClass]="{'btn btn-primary': producttemp.idorderoption!=ProductOption.cassetta, 'btn btn-success': producttemp.idorderoption==ProductOption.cassetta}"
                    data-toggle="button" aria-pressed="false" autocomplete="off" (click)="changeorderoption(ProductOption.cassetta)">
                    cassetta
                  </button> 
                  <button *ngIf="arrProductOption[ProductOption.Numero]" type="button" 
                    [ngClass]="{'btn btn-primary': producttemp.idorderoption!=ProductOption.Numero, 'btn btn-success': producttemp.idorderoption==ProductOption.Numero}"
                    data-toggle="button" aria-pressed="false" autocomplete="off" (click)="changeorderoption(ProductOption.Numero)">
                    Numero
                  </button> 
      
               </div>       
      
               <label for="inputnumberorder">Quantità:{{strqty}}</label>
               <label for="inputdescriptionorder">Quantità</label>
               <input type="number" name="qty" [(ngModel)]="strqty" class="form-control" id="inputdescriptionorder" aria-describedby="emailHelp" placeholder="" >
      
              </div>
            </div>

        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button class="btn btn-primary" (click)="sendorder()">Invia</button>
          </div>
      </div>
    </div>
  </div>

</section>





<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <form #MyForm="ngForm" (submit)="submitForm()">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Ordine Pronto</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

          <!--
            <div class="form-group">
                <label>Email address</label>
                <input type="text" class="form-control" name="email-field" placeholder="name@example.com" required
                    [(ngModel)]="model.email">
            </div>
            <div class="form-group">
                <label for="exampleFormControlSelect1">Example select</label>
                <select class="form-control" id="exampleFormControlSelect1" name="single-select" required
                    [(ngModel)]="model.singleSelect">
                    <option></option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                </select>
            </div>
            <div class="form-group">
                <label for="exampleFormControlSelect2">Example multiple select</label>
                <select multiple class="form-control" id="exampleFormControlSelect2" name="multi-select" required
                    [(ngModel)]="model.multiSelect">
                    <option></option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                </select>
            </div>
            <div class="form-group">
                <label for="exampleFormControlTextarea1">Example textarea</label>
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" name="textarea-field" required
                    [(ngModel)]="model.textareaField"></textarea>
            </div>
            <div class="custom-control custom-checkbox mb-3">
                <input type="checkbox" class="custom-control-input" id="customControlValidation1" name="condition-check" required  [(ngModel)]="model.checkbox">
                <label class="custom-control-label" for="customControlValidation1">Check this custom checkbox</label>
            </div>
          -->
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Inserire il costo</label>
              <input type="number" class="form-control" name="email-field" placeholder="inserire il costo del carrello" required
                  [(ngModel)]="model.euro">
            </div>
            
           

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button class="btn btn-primary" >Submit</button>
      </div>
    </div>
  </div>
</form>
</div>  <!--id="exampleModal"-->


</div>