import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Cart } from '../class/cart';
import { ApiResult } from '../class/apiresult'
import { Product } from '../class/product';
import { Message } from '../class/message';

@Injectable({ providedIn: 'root' })
export class CartService {

    private url = environment.apiUrl + 'api/cart';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }) //, 'Access-Control-Allow-Origin': '*' 
    };

    constructor(
        private http: HttpClient) {

    }
    getCart(cart: Cart | number): Observable<ApiResult> {
        const id = typeof cart === 'number' ? cart : cart.id;
        const url = `${this.url}/${id}`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('getCart ok')),
                catchError(this.handleError<ApiResult>('getCart'))
            );
    }
    delete(cart: Cart | number, product: Product): Observable<ApiResult> {
        const idcart = typeof cart === 'number' ? cart : cart.id;
        const idproduct = typeof product === 'number' ? product : product.id;
        const url = `${this.url}/${idcart}/delete/${idproduct}`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }
    sendmessage(message: Message): Observable<ApiResult> {
        const url = `${this.url}/sendmessage`;
        return this.http.post<ApiResult>(url, JSON.stringify(message))
            .pipe(
                tap(_ => this.log('sendmessage ok')),
                catchError(this.handleError<ApiResult>('sendmessage'))
            );
    }
    message(cart: Cart | number, flagAll: number): Observable<ApiResult> {
        const id = typeof cart === 'number' ? cart : cart.id;
        const url = `${this.url}/${id}/messages/${flagAll}`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }
    list(): Observable<ApiResult> {
        const url = `${this.url}`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }
    order(product: Product): Observable<ApiResult> {

        const url = `${this.url}/order`;
        return this.http.post<ApiResult>(url, JSON.stringify(product))
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }
    send(cart: Cart): Observable<ApiResult> {
        const id = cart.id;
        const url = `${this.url}/${id}/send`;
        return this.http.post<ApiResult>(url, cart.note)
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }
    ready(cart: Cart, euro: number): Observable<ApiResult> {
        const id = typeof cart === 'number' ? cart : cart.id;
        const o = { euro: euro }
        const url = `${this.url}/${id}/ready`;
        return this.http.post<ApiResult>(url, JSON.stringify(o))
            .pipe(
                tap(_ => this.log('addProduct ok')),
                catchError(this.handleError<ApiResult>('addProduct'))
            );
    }    //////// Save methods //////////


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a UserService message with the MessageService */
    private log(message: string) {
        console.log(`UserService: ${message}`);

    }
}
