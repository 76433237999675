import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Activity } from '../class/activity';
import { ApiResult } from '../class/apiresult'
import { Product } from '../class/product';

@Injectable({ providedIn: 'root' })
export class ActivityService {

    private url = environment.apiUrl + 'api/activity';  // URL to web api

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }) //, 'Access-Control-Allow-Origin': '*' 
    };

    constructor(
        private http: HttpClient) {

    }



    getActivity(activity: Activity | number): Observable<ApiResult> {
        const id = typeof activity === 'number' ? activity : activity.id;
        const url = `${this.url}/${id}`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('getProfileActivity ok')),
                catchError(this.handleError<ApiResult>('getProfileActivity'))
            );
    }
    getActivityfull(activity: Activity | number): Observable<ApiResult> {
        const id = typeof activity === 'number' ? activity : activity.id;
        const url = `${this.url}/${id}/full`;
        return this.http.get<ApiResult>(url)
            .pipe(
                tap(_ => this.log('getProfileActivity ok')),
                catchError(this.handleError<ApiResult>('getProfileActivity'))
            );
    }
    setActivity(activity: Activity | number, formData): Observable<ApiResult> {
        const id = typeof activity === 'number' ? activity : activity.id;
        const url = `${this.url}/${id}`;
        return this.http.post<ApiResult>(url, formData)
            .pipe(
                tap(_ => this.log('setProfileActivity ok')),
                catchError(this.handleError<ApiResult>('setProfileActivity'))
            );
    }


    //////// Save methods //////////


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a UserService message with the MessageService */
    private log(message: string) {
        console.log(`UserService: ${message}`);

    }
}
