import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService, Person, ServiceType } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';


@Component({
  selector: 'app-logon',
  templateUrl: './logon.component.html',
  styleUrls: ['./logon.component.css']
})
export class LogonComponent implements OnInit {

  public p: Person;
  private idactivity;
  private userservice: UserService;
  constructor(private http: HttpClient, private activateroute: ActivatedRoute,
    private route: Router, public spesaamica: SpesaAmica) {
    this.p = new Person();
    this.resetvalue();
    this.p.service = ServiceType.signin;
    this.idactivity = this.activateroute.snapshot.paramMap.get('id');
    if (this.idactivity == null) this.idactivity = "";



  }

  ngOnInit() {
  }
  resetvalue() {
    this.p.name = "";
    this.p.email = "";
    this.p.password = "";
    this.p.nameactivity = "";
    this.p.address = "";
    this.p.phone = "";
  }
  picksignin() {
    if (this.p.service != ServiceType.signin) {
      this.resetvalue();
      this.p.service = ServiceType.signin;
    }
  }
  pickregistercustomer() {
    if (this.p.service != ServiceType.registercustomer) {
      this.resetvalue();
      this.p.service = ServiceType.registercustomer;
    }
  }
  pickregisteractivity() {
    if (this.p.service != ServiceType.registeractivity) {
      this.resetvalue();
      this.p.service = ServiceType.registeractivity;
    }
  }

  logon() {
    this.userservice = new UserService(this.http);
    this.userservice.logon(this.p)
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          console.log(result.data)
          return;
        } else {
          this.spesaamica.user.id = result.data.id;
          this.spesaamica.user.name = result.data.name;
          this.spesaamica.user.type = result.data.type;
          this.spesaamica.user.idactivity = result.data.idactivity;
          switch (this.p.service) {
            case ServiceType.registeractivity:
              this.route.navigate(['/profileactivity']);
              break;
            case ServiceType.registercustomer:
              this.route.navigate(['/profilecustomer']);
              break;
            case ServiceType.signin:
              if (result.data.type == ServiceType.registeractivity) {
                this.route.navigate(['/activity/' + result.data.idactivity]);
              } else {
                if (this.idactivity != "") {
                  this.route.navigate(['/activity/' + this.idactivity]);
                } else {
                  this.route.navigate(['/useractivities']);
                }

              }
              break;
          }
        }
      })
  }
}
