import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';


import { SpesaamicaComponent } from './spesaamica/spesaamica.component';
import { UseractivitiesComponent } from './useractivities/useractivities.component';
import { LogonComponent } from './logon/logon.component';
import { HttpClientModule } from '@angular/common/http';
import { ProductactivityComponent } from './productactivity/productactivity.component';
import { ProductsorderComponent } from './productsorder/productsorder.component';
import { AdministratorComponent } from './administrator/administrator.component';
import { ErrorComponent } from './error/error.component';

//import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperModule } from './image-cropper/image-cropper.module';
import { ProfileActivityComponent } from './profileactivity/profileactivity.component';
import { ProfilecustomerComponent } from './profilecustomer/profilecustomer.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProductComponent } from './product/product.component';
import { CartComponent } from './cart/cart.component';
import { OrdersComponent } from './orders/orders.component';

import { ProductFilterPipe } from './pipes/productfilterpipe';
import { NgxImageCompressService } from 'ngx-image-compress';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SpesaamicasplashComponent } from './spesaamicasplash/spesaamicasplash.component';
//import { NgxOneSignalModule } from 'ngx-onesignal';


@NgModule({
  declarations: [
    AppComponent,
    SpesaamicaComponent,
    UseractivitiesComponent,
    LogonComponent,
    ProductactivityComponent,
    ProductsorderComponent,
    AdministratorComponent,
    ErrorComponent,
    ProfileActivityComponent,
    ProfilecustomerComponent,
    NavigationComponent,
    ProductComponent,
    CartComponent,
    OrdersComponent,
    ProductFilterPipe,
    SpesaamicasplashComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ImageCropperModule,
    ServiceWorkerModule.register('OneSignalSDKWorker.js', { enabled: environment.production }),
    //NgxOneSignalModule.forRoot({ appId: environment.ONESIGNAL_APP_ID }),

  ],
  providers: [NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
