import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivityService } from '../services/activity.service';
import { User, UserType } from '../class/user';
import { Activity } from '../class/activity';
import { SpesaAmica } from '../class/spesaamica';
//import { ImageCropper } from '../class/imagecropper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Dimensions, ImageCroppedEvent, ImageTransform } from '../image-cropper/interfaces/index';
import { base64ToFile } from '../image-cropper/utils/blob.utils';
import { ImageCropperComponent } from '../image-cropper/component/image-cropper.component';

declare var $: any;
@Component({
  selector: 'app-profileactivity',
  templateUrl: './profileactivity.component.html',
  styleUrls: ['./profileactivity.component.css']
})
export class ProfileActivityComponent implements OnInit {
  @ViewChild(ImageCropperComponent) imgCropper: ImageCropperComponent;
  activity: Activity;
  private activityservice: ActivityService;
  //imagecropper: ImageCropper;
  imageBase64String: any = '';
  typeimage: number; // person 0 activity 1
  activityimage: string;
  profileimage: string;
  aspectRatio: number;
  roundCropper: boolean;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient, private route: Router, public spesaamica: SpesaAmica,
    private imageCompress: NgxImageCompressService) {
    this.activityimage = "";
    this.profileimage = "";
  }
  ngOnInit(): void {
    var t = this;
    this.activity = new Activity();
    //this.imagecropper = new ImageCropper();
    this.spesaamica.load(function () {

      if (t.spesaamica.user.type != UserType.activity) {
        t.route.navigate(['/error/']);
        return;
      }
      t.activityservice = new ActivityService(t.http);
      t.activityservice.getActivity(t.spesaamica.user.idactivity)
        .subscribe(result => {
          if (result.statuscode) {
            alert(result.errorMessage);
            return;
          } else {
            t.activity.id = result.data.id;
            t.activity.namemanager = result.data.namemanager;
            t.activity.name = result.data.name;
            t.activity.address = result.data.address;
            t.activity.city = result.data.city;
            t.activity.description = result.data.description;
            t.activity.timetable = result.data.descriptiontimetable;
            t.activityimage = t.spesaamica.geturlactivityimage(t.activity.id);
            t.profileimage = t.spesaamica.geturlownerimage(t.activity.id);
          }
        })
    });

  }


  resetvalue(): void {
    $('#inputprofile').val(null);
    $('#inputact').val(null);
  }
  fileEvent(event: any, typeimage: any): void {

    debugger;
    if (event.target.files.length != 0) {
      console.log("fileEvent: " + event.target.files[0].name);
      if (typeimage == 0) {
        this.aspectRatio = 1 / 1;
        this.roundCropper = true;
      } else {
        this.aspectRatio = 16 / 9;
        this.roundCropper = false;
      }

      var file = event.target.files[0];
      this.typeimage = typeimage;
      $('#editphoto').modal('show');
      var reader = new FileReader();
      reader.onload = (event: any) => {
        //this.localUrl = event.target.result;
        //this.compressFile1(this.localUrl);
        this.compressFilebefore(event.target.result);

      }
      reader.readAsDataURL(file);


      //this.imagecropper.fileChangeEvent(event);
      //this.imagecropper.showcropper();
      //this.typeimage = typeimage;
      //$('#editphoto').modal('show');

    }

  }
  compressFilebefore(image) {
    var orientation = -1;
    var sizeOfOriginalImage = this.imageCompress.byteCount(image) / (1024 * 1024);
    console.warn('Size in bytes is now:', sizeOfOriginalImage);
    this.imageCompress.compressFile(image, orientation, 50, 50).then(
      result => {
        var sizeOFCompressedImagel5050 = this.imageCompress.byteCount(result) / (1024 * 1024)
        if (sizeOFCompressedImagel5050 > 2) {
          this.compressFilebefore(result);
        } else {
          this.imageBase64String = result;
          $('#inputprofile').val(null);
        }
        //this.localCompressedURl5050 = result;

      });
  }
  private dataURItoBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  imageCropped(event: ImageCroppedEvent) {
    if (this.typeimage == 0) {
      this.profileimage = event.base64;
    } else {
      this.activityimage = event.base64;
    }
  }

  compressFile(objorig, f) {
    if (!objorig.includes('data:image')) {
      f("")
    }
    var orientation = -1;
    var sizeOFCompressedImage = this.imageCompress.byteCount(objorig) / (1024 * 1024);
    if (sizeOFCompressedImage <= 2) {
      f(this.dataURItoBlob(objorig));
      return;
    }
    this.imageCompress.compressFile(objorig, orientation, 50, 100).then(
      result => {
        var sizeOFCompressedImage = this.imageCompress.byteCount(result) / (1024 * 1024);
        if (sizeOFCompressedImage > 2) {
          this.compressFile(result, f);
          return
        } else {
          f(this.dataURItoBlob(result));
          return;
        }
      });
  }
  saveprofile() {
    debugger;
    var formData = new FormData();
    this.compressFile(this.profileimage, result => {
      if (result == "") {
        formData.append('flagimageperson', 'false');
      } else {
        formData.append('flagimageperson', 'true');
        formData.append('croppedImageperson', result)
      }
      this.compressFile(this.activityimage, result => {
        if (result == "") {
          formData.append('flagimageactivity', 'false');
        } else {
          formData.append('flagimageactivity', 'true');
          formData.append('croppedImageactivity', result)
        }
        formData.append('activity', JSON.stringify(this.activity));

        this.activityservice.setActivity(this.spesaamica.user.idactivity, formData)
          .subscribe(result => {
            if (result.statuscode) {
              alert(result.errorMessage);
              return;
            } else {
              this.route.navigate(['/activity/' + this.spesaamica.user.idactivity]);
            }

          })
      })
    })
    return;
  }
}
