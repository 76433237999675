import { environment } from '../../environments/environment';
export class OneSignal {
    constructor(appid, userid) {


        //if (!environment.production) return;
        var OneSignal = window['OneSignal'] || [];
        console.log("Init OneSignal");
        OneSignal.push(["init", {
            appId: appid,
            autoRegister: false,
            allowLocalhostAsSecureOrigin: true,
            notifyButton: {
                enable: false
            }
        }]);
        OneSignal.push(["getNotificationPermission", function (permission) {
            console.log("Site Notification Permission:", permission);
            // (Output) Site Notification Permission: default
        }]);
        console.log('OneSignal Initialized');
        OneSignal.setExternalUserId(userid);
        console.log('OneSignal set External user');
        OneSignal.push(function () {
            console.log('Register For Push');
            OneSignal.push(["registerForPushNotifications"])
        });
        OneSignal.push(function () {
            // Occurs when the user's subscription changes to a new value.
            OneSignal.on('subscriptionChange', function (isSubscribed) {
                console.log("The user's subscription state is now:", isSubscribed);
                OneSignal.getUserId().then(function (userId) {
                    console.log("User ID is", userId);
                });
            });
        });
    }

}