import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { SpesaAmica } from '../class/spesaamica';
import { Activity } from '../class/activity';


@Component({
  selector: 'app-useractivities',
  templateUrl: './useractivities.component.html',
  styleUrls: ['./useractivities.component.css']
})
export class UseractivitiesComponent implements OnInit {
  public activities: Activity[];
  public imageprova: string = "http://localhost/spesaamicanew/img/bg.jpg";
  constructor(private http: HttpClient, private route: Router,
    public spesaamica: SpesaAmica, private userservice: UserService) {
    this.activities = [];

  }

  ngOnInit() {
    this.spesaamica.initmenu();

    this.userservice.activity()
      .subscribe(result => {
        if (result.statuscode) {
          alert(result.errorMessage);
          return;
        } else {
          debugger;
          console.log(result.data);
          this.activities = result.data;
          this.activities.map(a => {
            a.activityimage = this.spesaamica.geturlactivityimage(a.id);
            a.ownerimage = this.spesaamica.geturlownerimage(a.id);
          });

        }

      })



  }




}
