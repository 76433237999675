import { SettingService, Setting } from '../services/setting.service';
import { UserService, Person, ServiceType } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { OneSignal } from '../class/onesignal';
import { Router } from '@angular/router';
export enum UserType {
    none = 0,
    activity = 1,
    customer = 2,
    administrator = 3
}

export class User {
    public id: number;
    public name: string;
    public type: UserType;
    public idactivity: number;
    private settingService: SettingService;
    private userservice: UserService;

    constructor(private http: HttpClient, private route: Router) {
        this.id = 0;
        this.name = "";
        this.type = UserType.none;
    }
    load(f): void {
        this.settingService = new SettingService(this.http);
        this.settingService.getSettingUserActivities()
            .subscribe(result => {
                if (result.statuscode) {
                    alert(result.errorMessage);
                    return;
                } else {
                    this.arrivedSetting(result.data, f);

                }

            })
    }

    arrivedSetting(setting: Setting, f): void {
        if (setting.user.type != UserType.none) {
            this.id = setting.user.id;
            this.name = setting.user.name;
            this.type = setting.user.type;
            this.idactivity = setting.user.idactivity;
            if (setting.appid != "") new OneSignal(setting.appid, setting.user.id);
        }
        f();
    }
    logoff() {
        this.userservice = new UserService(this.http);
        this.userservice.logoff().subscribe(result => {
            if (result.statuscode) {
                alert(result.errorMessage);
            } else {
            }

        });
        this.route.navigate(['/spesaamica']);
    }

}