import { Component, OnInit, Input } from '@angular/core';
import { SpesaAmica } from '../class/spesaamica';
import { User, UserType } from '../class/user';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  UserType = UserType; // serve al component.html
  constructor(public spesaamica: SpesaAmica) {
  }

  ngOnInit(): void {
  }
  logoff(): void {
    this.spesaamica.user.logoff();
  }
}
